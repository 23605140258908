<template>
    <div class="h-100 w-100">
        <MapViewer class="p-0" @map-ready="onMapReady" />
    </div>
</template>

<script>
import MapViewer from "./MapViewer";
import { LExtended as L } from "geoportal-extensions-leaflet";

const basePin = L.divIcon({
    className: "k-map-pin-base",
    iconAnchor: [20, 40],
    iconSize: [40, 40],
    popupAnchor: [0, -40],
    html: "<div/>",
});

export default {
    components: {
        MapViewer,
    },

    props: {
        geometries: { type: Array, required: false },
    },

    data: function() {
        return {
            map: null,
            geojson: null,
            group: null,
        };
    },

    watch: {
        geometries() {
            if (this.map) {
                this.initContent();
            }
        },
    },

    methods: {
        onMapReady(map) {
            this.map = map;

            this.initContent();
        },

        initContent() {
            if (!this.group) {
                this.group = L.featureGroup([]).addTo(this.map);
            } else {
                this.group.clearLayers();
            }
            this.createGeoJSON(this.geometries);
            this.centerMapOnGroup();
        },

        createGeoJSON(geometries) {
            let geo = L.geoJSON(geometries, {
                style: {
                    color: "#00403d",
                    fillColor: "#00817b",
                    fillOpacity: 0.2,
                    weight: 3,
                    opacity: 1,
                    icon: basePin,
                },
                pointToLayer: function(feature, latlng) {
                    return L.marker(latlng, { icon: basePin });
                },
                onEachFeature: function(feature, layer) {
                    if (feature.properties && feature.properties.content) {
                        layer.bindPopup(feature.properties.content);
                    }
                },
            }).addTo(this.group);
            this.geojson = geo;
        },

        centerMapOnGroup() {
            if (this.geometries && this.geometries.length > 0) {
                this.map.flyToBounds(this.group.getBounds().pad(0.3), {
                    maxZoom: 13,
                    animate: false,
                });
            }
        },
    },
};
</script>
