<template>
    <div class="k-timeline">
        <div class="k-timeline-moment">
            <div class="k-timeline-time">{{ event.time | dateFromISO }}</div>
            <div class="k-timeline-bubble">
                <div :style="`background:${stateColor}`" />

                <div v-if="event.type === 'build.started'">
                    Début de la génération
                </div>

                <div v-else-if="event.type === 'source.fetched'">
                    <div class="text-kalkin-2">
                        <b-icon-download /> Données récupérées avec succès
                    </div>
                    <div>
                        <small> Identifiant : </small>
                        <b-badge class="mx-1">{{ event.details.id }}</b-badge>
                    </div>
                    <div>
                        <small> Nom du flux : </small>
                        <span>{{ event.details.name }}</span>
                    </div>
                    <div>
                        <small>Nombre de fiches : </small>
                        <b>{{ event.details.count }}</b>
                    </div>
                </div>

                <div v-else-if="event.type === 'source.ignored'">
                    <div class="text-warning">
                        <b-icon-backspace-reverse /> Données ignorées
                    </div>
                    <div>
                        <small> Identifiant : </small>
                        <b-badge class="mx-1">{{ event.details.id }}</b-badge>
                    </div>
                    <div>
                        <small> Nom du flux : </small>
                        <span>{{ event.details.name }}</span>
                    </div>
                    <div>
                        <small>Langue : </small>
                        <b>{{ event.details.lang }}</b>
                    </div>
                </div>

                <div v-else-if="event.type === 'filter'">
                    <div class="text-kalkin-3">
                        <b-icon-arrow-return-right /> Filtrage des données
                    </div>
                    <div>
                        <small> Type de filtre : </small>
                        <span>{{ event.details.type }}</span>
                    </div>
                    <div>
                        <small>Evolution du nombre de fiches : </small>
                        <b>{{ event.details.before }} </b>
                        <b-icon-arrow-right />
                        <b> {{ event.details.after }}</b>
                    </div>
                </div>

                <div v-else-if="event.type === 'source.applied'">
                    <div class="text-kalkin-2">
                        <b-icon-check2-square /> Données ajoutées au menu
                    </div>
                    <div>
                        <small> Identifiant du flux : </small>
                        <b-badge class="mx-1">{{ event.details.id }}</b-badge>
                    </div>
                    <div>
                        <small> Nom du menu : </small>
                        <span>{{ event.details.menu }}</span>
                    </div>
                    <div>
                        <small>Nombre de fiches : </small>
                        <b>{{ event.details.count }}</b>
                    </div>
                </div>

                <div v-else-if="event.type === 'source.fetch.error'">
                    <div class="text-danger">
                        Erreur lors de la récupération des données
                    </div>
                    <div>
                        <small> Identifiant : </small>
                        <b-badge class="mx-1">{{ event.details.id }}</b-badge>
                    </div>
                    <div>
                        <small> Nom du flux : </small>
                        <span>{{ event.details.name }}</span>
                    </div>
                    <div>
                        <small>Détails de l'erreur : </small>
                        <pre class="border rounded p-1 m-0">{{
                            event.details.error
                        }}</pre>
                    </div>
                </div>

                <div v-else-if="event.type === 'build.failed'">
                    <div class="text-danger">
                        Echec de la génération
                    </div>
                    <div>
                        <small>Détails de l'erreur : </small>
                        <pre class="border rounded p-1 m-0">{{
                            event.details.error
                        }}</pre>
                    </div>
                </div>

                <div v-else-if="event.type === 'build.done'">
                    Fin de la génération
                </div>
                <div v-else>
                    {{ event.type }}
                    {{ event }}
                </div>
            </div>
        </div>
        <div class="k-timeline-span" v-if="next">
            <div class="k-timeline-time">
                <i>+ {{ difftime }} ms</i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        event: { type: Object, required: true },
        next: { type: Object, required: false },
    },

    computed: {
        difftime() {
            if (this.next) {
                return (
                    new Date(this.next.time).getTime() -
                    new Date(this.event.time).getTime()
                );
            } else {
                return "";
            }
        },

        stateColor() {
            switch (this.event.level) {
                case "Info":
                case "Success":
                    return "#92bb3f";
                case "FatalError":
                case "FluxError":
                    return "red";
                case "Warning":
                    return "orange";
                default:
                    return "#fff";
            }
        },
    },
};
</script>

<style lang="scss">
.k-timeline > div {
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;

    padding-left: 40px;
}

.k-timeline-moment {
    min-height: 40px;

    &::before {
        content: "";
        position: absolute;
        left: 20px;

        height: 100%;
        border-left: 2px solid $primary-color;
    }

    &::after {
        content: "";
        position: absolute;
        top: calc(50% - 5px);
        left: 16px;

        width: 10px;
        height: 10px;
        border-radius: 5px;
        border: 2px solid $primary-color;
        background: white;
    }
}
.k-timeline-span::before {
    content: "";
    position: absolute;
    left: 20px;

    height: 100%;
    border-left: 2px dotted $primary-color;
}

.k-timeline-time {
    flex: none;
    width: 140px;
    font-size: 12px;
    color: #888;
}

.k-timeline-bubble {
    position: relative;
    flex: auto;
    padding: 10px 15px;

    border: 1px solid #ddd;
    border-radius: 6px;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        left: -8px;
        top: calc(50% - 4px);

        width: 1px;
        border-top: 4px solid #00000000;
        border-bottom: 4px solid #00000000;
        border-right: 8px solid #ccc;
    }

    & > div:first-child {
        position: absolute;
        left: 0;
        top: 0;
        width: 10px;
        height: 100%;
        border-radius: 0 10px 10px 0;
        border-right: 1px solid #ddd;
    }
}
</style>
