<template>
    <div class="k-flex-col h-100">
        <div class="k-flex-row flex-nowrap">
            <b-form-select
                size="sm"
                v-model="selected"
                :options="dataselectionOptions"
                :disabled="isReady"
            >
                <template #first>
                    <b-form-select-option :value="null" disabled>
                        -- Choisir une sélection de données --
                    </b-form-select-option>
                </template>
            </b-form-select>

            <b-button
                size="sm"
                variant="secondary"
                @click="reset"
                v-show="isReady"
            >
                Réinitialiser
            </b-button>
            <b-button
                size="sm"
                variant="outline-secondary"
                v-b-toggle.collapse-settings
                v-show="isReady"
            >
                <b-icon-gear-fill />
            </b-button>
        </div>

        <b-collapse id="collapse-settings">
            <div class="k-flex-center k-gap-10 border rounded p-3">
                <div>
                    <div class="text-kalkin-1 k-text-sm">Fiches par page</div>
                    <b-form-radio-group
                        v-model="fichesPerPage"
                        size="sm"
                        button-variant="outline-kalkin-1"
                        buttons
                    >
                        <b-form-radio :value="5">5</b-form-radio>
                        <b-form-radio :value="15">15</b-form-radio>
                        <b-form-radio :value="30">30</b-form-radio>
                        <b-form-radio :value="50">50</b-form-radio>
                        <b-form-radio :value="100">100</b-form-radio>
                    </b-form-radio-group>
                </div>

                <div class="ml-4">
                    <div class="text-kalkin-1 k-text-sm">Mode JSON</div>
                    <b-form-checkbox class="ml-4" v-model="enableJSON" switch />
                </div>
            </div>
        </b-collapse>

        <b-button
            class="align-self-center px-3"
            size="sm"
            variant="kalkin-2"
            @click="isReady = true"
            v-show="!isReady"
            :disabled="!selected"
        >
            Valider la sélection
        </b-button>

        <template v-if="selected && isReady">
            <template v-if="preview === null">
                <b-overlay
                    class="k-flex-col k-flex-center flex-grow-1 p-3"
                    variant="transparent"
                    spinner-variant="kalkin-1"
                    :show="isLoading"
                >
                    <b-button
                        size="sm"
                        variant="kalkin-2"
                        @click="fetchPreview(false)"
                        :disabled="isLoading"
                    >
                        Télécharger les données
                    </b-button>
                    - ou -
                    <b-button
                        size="sm"
                        variant="secondary"
                        @click="fetchPreview(true)"
                        :disabled="isLoading"
                    >
                        Forcer la mise à jour du cache
                    </b-button>
                </b-overlay>
            </template>
            <template v-else>
                <small class="font-italic text-right text-kalkin-2">
                    Données mises en cache le
                    {{ preview.createdAt | dateFromISO }} - Nombre de fiches:
                    {{ preview.fiches.length }}
                </small>
                <div class="panel-preview">
                    <ListFiches
                        :fiches="preview.fiches"
                        :per-page="fichesPerPage"
                        @select-fiche="selectedFiche = $event"
                        @display-geometry="geometryToDisplay = $event"
                    />
                    <template v-if="selectedFiche">
                        <PanelFiche :fiche="selectedFiche" :json="enableJSON" />
                    </template>
                    <template v-else-if="geometryToDisplay">
                        <MapGeoJSONViewer
                            :geometries="geometryToDisplay"
                            style="max-height: 90vh"
                        />
                    </template>
                    <template v-else-if="error">
                        <b-card>
                            <template v-for="(event, idx) in error.data">
                                <ItemBuildEvent
                                    :key="idx"
                                    :event="event"
                                    :next="error.data[idx + 1]"
                                />
                            </template>
                        </b-card>
                    </template>
                    <template v-else>
                        <b-card class="text-center">
                            Sélectionner une fiche ci-contre
                        </b-card>
                    </template>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import ListFiches from "@/components/model/dataselection/fiche/ListFiches";
import PanelFiche from "@/components/model/dataselection/fiche/PanelFiche";
import MapGeoJSONViewer from "@/components/map/MapGeoJSONViewer";
import ItemBuildEvent from "@/components/model/arbobuild/ItemBuildEvent";

export default {
    components: {
        ListFiches,
        PanelFiche,
        MapGeoJSONViewer,
        ItemBuildEvent,
    },

    props: {
        projectId: { type: [Number, String], required: true },
        dataselections: { type: Array, required: true },
    },

    data: function() {
        return {
            selected: null,
            preview: null,
            error: null,

            isReady: false,
            isLoading: false,

            selectedFiche: null,
            geometryToDisplay: null,

            fichesPerPage: 15,
            enableJSON: false,
        };
    },

    computed: {
        dataselectionOptions() {
            return this.dataselections
                .map(ds => ({
                    value: ds.id,
                    text: ds.name,
                }))
                .sort((a, b) => a.text.localeCompare(b.text));
        },
    },

    methods: {
        async fetchPreview(refresh = false) {
            this.isLoading = true;
            this.preview = await this.$store.dispatch(
                "datasource/getDataSelectionPreview",
                {
                    projectId: this.projectId,
                    dataselectionId: this.selected,
                    refresh,
                },
            );

            if (this.preview.error) {
                this.error = this.preview.error;
            } else {
                this.error = null;
            }

            this.isLoading = false;
        },

        reset() {
            this.isReady = false;
            this.preview = null;
            this.selectedFiche = null;
            this.geometryToDisplay = null;
        },
    },
};
</script>

<style lang="scss" scoped>
.panel-preview {
    display: flex;
    height: 100%;

    & > *:first-child {
        min-width: 200px;
        width: 33%;
    }

    & > *:last-child {
        margin: 4px 2px 0 8px;
        flex: 1;
    }
}
</style>
