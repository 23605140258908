<template>
    <div class="k-flex-col">
        <div>
            <label>
                La source de données {{ localDatasource.name }} sera supprimée.
            </label>
        </div>
        <div>
            <label>
                Attention : Toutes les sélections de données et les associations
                à un menu seront supprimées.
            </label>
        </div>
        <div>
            <b-button size="sm" variant="kalkin-2" @click="onDataSourceDelete">
                Supprimer
            </b-button>
        </div>
        <ModalDanger
            @confirmed="onDeleteConfirmed"
            message="Voulez vous vraiment supprimer cette source de données ?"
            details="Toutes les sélections de données et les associations à un menu seront supprimées."
        />
    </div>
</template>
<script>
import Vue from "vue";
import ModalDanger from "@/components/modal/ModalDanger";

const defaultDatasource = {
    data: {},
};

export default {
    components: {
        ModalDanger,
    },

    props: {
        projectId: { type: [String, Number], required: true },
        datasource: { type: Object, required: false },
    },

    data: function() {
        return {
            localDatasource: Vue.util.extend(
                {},
                this.datasource || defaultDatasource,
            ),
        };
    },

    methods: {
        async onDataSourceDelete(dataSource) {
            this.selectedDataSource = dataSource;
            this.$bvModal.show("ModalDanger");
        },

        async onDeleteConfirmed() {
            await this.$store.dispatch("datasource/deleteDataSource", {
                projectId: this.projectId,
                datasourceId: this.datasource.id,
            });
            this.$router.push({
                name: "AdminProjectListDatasources",
                params: {
                    projectId: this.projectId,
                },
            });
        },
    },
};
</script>
