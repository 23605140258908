<template>
    <div>
        <div v-for="[k, v] in Object.entries(content)" :key="k">
            <div class="text-kalkin-2">{{ k }}</div>

            <div class="content-value">
                <template v-if="k === 'medias'">
                    <ImagePreview
                        v-for="m in v"
                        :key="m.url"
                        :url="m.url"
                        width="200px"
                        height="170px"
                    />
                </template>
                <template v-else-if="k === 'difficulty'">
                    <b-rating
                        variant="kalkin-1"
                        :value="v.value"
                        :stars="v.max_value"
                        readonly
                        inline
                    />
                    {{ v.text }}
                </template>
                <template v-else-if="Array.isArray(v)">
                    <div class="border rounded" v-for="(r, i) in v" :key="i">
                        {{ r }}
                    </div>
                </template>
                <template v-else>{{ v }}</template>
            </div>
        </div>
    </div>
</template>

<script>
import ImagePreview from "@/components/ui/item/ImagePreview";
export default {
    components: {
        ImagePreview,
    },

    props: {
        content: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.content-value {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;

    margin: 0 0.6rem;
    padding: 0.5rem 0.4rem;
    border-left: 3px solid $secondary-color;

    font-size: 0.85em;
    white-space: pre-wrap;
}
</style>
