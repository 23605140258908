<template>
    <div class="k-flex-col fiche">
        <template v-if="fiche && !json">
            <h4 class="text-center">{{ fiche.name }}</h4>

            <div class="k-flex-row text-kalkin-1 font-weight-bold">
                Identifiant SIT
                <div class="k-flex-line" />
            </div>
            <div v-if="fiche.origin">{{ fiche.origin.id }}</div>
            <div v-else><i>Inconnu</i></div>

            <div class="k-flex-row text-kalkin-1 font-weight-bold">
                Type
                <div class="k-flex-line" />
            </div>
            <div v-if="fiche.type">{{ fiche.type }}</div>
            <div v-else><i>Pas de type</i></div>

            <div class="k-flex-row text-kalkin-1 font-weight-bold">
                Géographie
                <div class="k-flex-line" />
            </div>
            <div style="height:300px" v-if="geometries">
                <MapGeoJSONViewer :geometries="geometries" />
            </div>
            <div v-else><i>Pas de géométrie</i></div>

            <div class="k-flex-row text-kalkin-1 font-weight-bold">
                Contenu
                <div class="k-flex-line" />
            </div>
            <PanelContent :content="fiche.content" />

            <div class="k-flex-row text-kalkin-1 font-weight-bold">
                Données de filtrage
                <div class="k-flex-line" />
            </div>
            <template v-if="filter">
                <div v-for="[k, v] in filter" :key="k">
                    <div class="text-kalkin-2">{{ k }}</div>
                    <div class="ml-2 k-text-sm">{{ v }}</div>
                </div>
            </template>
            <div v-else><i>Pas de données de filtrage</i></div>

            <template v-if="fiche.order">
                <div class="k-flex-row text-kalkin-1 font-weight-bold">
                    Données de tri
                    <div class="k-flex-line" />
                </div>
                <div>{{ fiche.order }}</div>
            </template>

            <template v-if="fiche.fiches">
                <div class="k-flex-row text-kalkin-1 font-weight-bold">
                    Points d'intérêt associés
                    <div class="k-flex-line" />
                </div>
                <GridCardBasic :data="fiche.fiches" minCardSize="300px">
                    <template v-slot="{ data }">
                        <div class="k-card-3">
                            <div class="title">
                                {{ data.name || "~Fiche sans nom~" }}
                            </div>
                            <PanelContent :content="data.content" />
                        </div>
                    </template>
                </GridCardBasic>
            </template>
        </template>
        <template v-else-if="fiche && json">
            <pre class="k-text-sm">{{ fiche }}</pre>
        </template>
        <template v-else>
            <div class="text-center my-3">
                Sélectionner une fiche ci-contre
            </div>
        </template>
    </div>
</template>

<script>
import MapGeoJSONViewer from "@/components/map/MapGeoJSONViewer";
import PanelContent from "./PanelContent";
import GridCardBasic from "@/components/ui/GridCardBasic";

export default {
    components: {
        MapGeoJSONViewer,
        PanelContent,
        GridCardBasic,
    },

    props: {
        fiche: { type: Object },
        json: { type: Boolean, default: false },
    },

    computed: {
        filter() {
            if (this.fiche && this.fiche.filter) {
                return Object.entries(this.fiche.filter);
            } else {
                return null;
            }
        },

        geometries() {
            if (this.fiche && this.fiche.geo) {
                return [this.fiche.geo, ...this.children];
            } else {
                return null;
            }
        },

        children() {
            if (this.fiche && this.fiche.fiches) {
                return this.fiche.fiches.reduce((a, f) => {
                    if (f.geo) {
                        a.push({
                            type: "Feature",
                            geometry: f.geo,
                            properties: {
                                content: f.name,
                            },
                        });
                    }
                    return a;
                }, []);
            } else {
                return [];
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.fiche {
    padding: 10px;
    border: 1px solid #aaa;
    border-radius: 6px;
    background-color: #fafafa;
}
</style>
