<template>
    <div class="k-flex-col">
        <div class="k-flex-row k-flex-fill">
            <LabelledInputText
                label="Nom"
                placeholder="Nom de la sélection"
                :required="true"
                v-model="localDataselection.name"
                @validated="isNameValid = $event"
            />
            <LabelledInput label="Type de contenu" :required="true">
                <b-form-select
                    v-model="localDataselection.data.contentType"
                    :options="contentTypeOptions"
                />
                <b-input-group-append>
                    <b-input-group-text id="help-content-type">
                        <b-icon-question-circle />
                    </b-input-group-text>
                    <b-popover
                        target="help-content-type"
                        triggers="hover"
                        placement="bottomleft"
                        variant="info"
                        :content="helpContentType"
                    >
                    </b-popover>
                </b-input-group-append>
            </LabelledInput>
        </div>

        <div class="text-right font-italic" v-show="isEditMode">
            <small>
                <b-icon-info-circle />
                Création le
                {{ localDataselection.createdAt | dateFromISO }} ~ Dernière
                modification le
                {{ localDataselection.updatedAt | dateFromISO }}
            </small>
        </div>

        <GenericForm
            class="mt-2"
            v-bind="genericFormConfig"
            @validated="isGFValid = $event"
            @updated="localDataselection.data = $event"
        />

        <b-form-group>
            <label>Langue</label>
            <b-form-radio-group
                v-model="localDataselection.LangId"
                :options="langs"
            >
                <template #first>
                    <b-form-radio value="null">
                        <b class="text-kalkin-1">
                            Langue par défaut / multilingue
                        </b>
                    </b-form-radio>
                </template>
            </b-form-radio-group>
        </b-form-group>

        <div>
            <b-button
                class="w-100"
                v-b-toggle.ds-edit-advanced
                variant="kalkin-1"
                size="sm"
            >
                Options avancées <b-icon-gear-fill />
            </b-button>
            <b-collapse id="ds-edit-advanced" class="k-collapse">
                <b-form-group>
                    <label>Options de préfiltrage</label>
                    <JSONTextArea
                        v-model="prefilter"
                        @validate="isPrefilterValid = $event"
                    />
                </b-form-group>
                <b-form-group>
                    <label>Modules</label>
                    <JSONTextArea
                        v-model="modules"
                        @validate="isModulesValid = $event"
                    />
                </b-form-group>
            </b-collapse>
        </div>
        <div class="k-flex-center pt-3">
            <b-button variant="kalkin-2" @click="save" :disabled="!isValid">
                Enregistrer
            </b-button>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { get as _get } from "lodash";
import LabelledInput from "@/components/ui/item/LabelledInput";
import LabelledInputText from "@/components/ui/item/LabelledInputText";
import GenericForm from "@/components/form-generator/GenericForm";
import Formatter from "@/tools/format";
import JSONTextArea from "@/components/ui/item/JSONTextArea";
import Langs from "@/config/lang";

const defaultDataselection = {
    prefilter: null,
    data: { contentType: "auto" },
    LangId: null,
};

export default {
    components: {
        LabelledInput,
        LabelledInputText,
        GenericForm,
        JSONTextArea,
    },

    props: {
        projectId: { type: [String, Number], required: true },
        datasource: { type: Object, required: true },
        dataselection: { type: Object, required: false },
    },

    data: function() {
        return {
            contentTypeOptions: [
                { text: "Automatique", value: "auto" },
                { text: "Service", value: "service" },
                { text: "Rando", value: "rando" },
                { text: "Circuit", value: "circuit" },
                { text: "Média", value: "media" },
                { text: "Domaine", value: "domaine" },
                { text: "Ski", value: "ski" },
            ],

            isEditMode: this.dataselection != null,
            isNameValid: this.dataselection != null,
            isPrefilterValid: true,
            isModulesValid: true,
            isGFValid: false,

            localDataselection: Vue.util.extend(
                {},
                this.dataselection || defaultDataselection,
            ),
            prefilter: this.initPrefilter(),
            modules: this.initModules(),
        };
    },

    watch: {
        dataselection() {
            this.prefilter = this.initPrefilter();
            this.modules = this.initModules();
        },
    },

    computed: {
        langs() {
            return Langs.map(l => {
                return {
                    html: `
                    <div class="k-flex-row text-kalkin-1">
                        <small>${l.name}</small>
                        <span class="flag:${l.key}" />
                    </div>`,
                    value: l.key,
                };
            });
        },

        isValid() {
            return this.isPrefilterValid && this.isNameValid && this.isGFValid;
        },

        genericFormConfig() {
            let config = {};
            try {
                config = {
                    schema: {
                        ...Formatter.convertCOREtoFORM(
                            this.datasource.DataSourceType.data.format
                                .dataselection,
                        ),
                    },
                    model: this.localDataselection.data,
                    payload: { datasource: this.datasource },
                };
            } catch (error) {
                //nothing
            }
            return config;
        },

        helpContentType() {
            return {
                auto:
                    "Affectation automatique pour chaque fiche, selon si elle possède une trace et des POI (⇒Circuit), seulement une trace (⇒Rando) ou juste un point (⇒Service)",
                service:
                    "Les fiches sont représentées par un point unique, avec des informations relatives aux services qu'elles proposent",
                rando:
                    "Les fiches sont représentées par un tracé GPS, avec des informations relatives au parcours",
                circuit:
                    "Les fiches sont représentées par un tracé GPS, avec des informations relatives au parcours, et avec en plus un ensemble de points d'intérêt le long du parcours",
                media:
                    "Les fiches sont représentées par un média non géolocalisé",
            }[this.localDataselection.data.contentType];
        },
    },

    methods: {
        initPrefilter() {
            let prefilter = _get(this.dataselection, "prefilter");
            if (prefilter) {
                return JSON.stringify(prefilter);
            } else {
                return null;
            }
        },

        initModules() {
            let m = _get(this.dataselection, "data.modules");
            if (m) {
                return JSON.stringify(m);
            } else {
                return null;
            }
        },

        async save() {
            if (this.isEditMode) {
                await this.$store.dispatch("datasource/updateDataSelection", {
                    projectId: this.projectId,
                    dataselectionId: this.localDataselection.id,
                    name: this.localDataselection.name,
                    prefilter: this.prefilter,
                    data: {
                        ...this.localDataselection.data,
                        modules: this.modules
                            ? JSON.parse(this.modules)
                            : undefined,
                    },
                    langId: this.localDataselection.LangId,
                });
            } else {
                await this.$store.dispatch("datasource/createDataSelection", {
                    projectId: this.projectId,
                    datasourceId: this.datasource.id,
                    name: this.localDataselection.name,
                    prefilter: this.prefilter,
                    data: {
                        ...this.localDataselection.data,
                        modules: this.modules
                            ? JSON.parse(this.modules)
                            : undefined,
                    },
                    langId: this.localDataselection.LangId,
                });
            }
            this.$emit("saved");
        },
    },
};
</script>
