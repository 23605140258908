<template>
    <div class="k-flex-col">
        <div class="k-flex-row mt-2">
            <div class="flex-grow-1 mr-4">
                <b-input-group>
                    <template #prepend>
                        <b-input-group-text>
                            <b-icon-search />
                        </b-input-group-text>
                    </template>
                    <b-form-input
                        placeholder="Rechercher une sélection"
                        v-model="filter"
                        debounce="10"
                    />
                </b-input-group>
            </div>
            <div class="d-flex flex-column">
                <small class="text-kalkin-1 text-center w-100">
                    Sélections par page
                </small>
                <b-form-group>
                    <b-form-radio-group
                        v-model="perPage"
                        size="sm"
                        button-variant="outline-kalkin-1"
                        buttons
                    >
                        <b-form-radio :value="5">5</b-form-radio>
                        <b-form-radio :value="10">10</b-form-radio>
                        <b-form-radio :value="20">20</b-form-radio>
                        <b-form-radio :value="50">50</b-form-radio>
                        <b-form-radio :value="100">100</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
        </div>
        <b-table
            :items="dataselections"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :busy="isLoading"
            @row-clicked="onRowClicked"
            @filtered="filtered = $event"
            thead-class="text-center"
            tbody-tr-class="text-center pointer"
            empty-filtered-text="Aucune sélection trouvée"
            empty-text="Aucune sélection"
            show-empty
            hover
            outlined
            stacked="lg"
        >
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Chargement des données...</strong>
                </div>
            </template>
            <template #cell(delete)="row">
                <button
                    type="button"
                    v-b-tooltip.right.v-danger
                    title="Supprimer"
                    class="btn btn-sm btn-outline-danger"
                    @click="onDelete(row.item)"
                >
                    <b-icon-trash />
                </button>
            </template>
        </b-table>
        <div>
            <b-pagination
                class="m-auto"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalFilteredRows"
                align="center"
                size="sm"
                first-number
                last-number
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dataselections: { type: Array, required: true },
        isLoading: { type: Boolean, default: true },
    },

    data: function() {
        return {
            perPage: 10,
            currentPage: 1,
            filter: "",
            fields: [
                {
                    key: "selection",
                    label: "Id",
                    sortable: true,
                    tdClass: "text-left",
                },
                {
                    key: "name",
                    label: "Nom",
                    sortable: true,
                    tdClass: "text-left",
                },
                {
                    key: "data.contentType",
                    label: "Type de contenu",
                    sortable: true,
                },
                {
                    key: "lang",
                    label: "Language",
                    sortable: true,
                },
                {
                    key: "menuCount",
                    label: "Utilisations",
                    sortable: true,
                },
                {
                    key: "createdAt",
                    label: "Date de création",
                    sortable: true,
                    formatter: this.formatDate,
                },
                {
                    key: "updatedAt",
                    label: "Date de modification",
                    sortable: true,
                    formatter: this.formatDate,
                },
                {
                    key: "delete",
                    label: "",
                },
            ],
            filtered: [],
        };
    },

    watch: {
        dataselections(v) {
            this.filtered = v;
            this.filtered.forEach(function(arr) {
                arr.LangId === null
                    ? (arr.lang = "auto")
                    : (arr.lang = arr.LangId);
                arr.data.selection
                    ? (arr.selection = arr.data.selection)
                    : (arr.selection = arr.data.searchId);
            });
        },
    },

    computed: {
        totalFilteredRows() {
            return this.filtered ? this.filtered.length : 0;
        },
    },

    methods: {
        formatDate(value) {
            const d = new Date(value);
            return `${d.toLocaleDateString()} à ${d.toLocaleTimeString()}`;
        },

        onRowClicked(item) {
            this.$emit("item-clicked", item);
        },

        onDelete(item) {
            this.$emit("item-delete", item);
        },
    },
};
</script>
