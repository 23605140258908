<template>
    <div class="k-flex-col k-gap-10 p-1">
        <div>
            <b-input-group size="sm">
                <template #prepend>
                    <b-input-group-text>
                        <b-icon-search />
                    </b-input-group-text>
                </template>
                <b-form-input
                    placeholder="Rechercher une fiche"
                    v-model="filter"
                    @update="onPageChanged"
                    debounce="500"
                />
            </b-input-group>
        </div>

        <div v-if="totalRows > 0" class="border">
            <div class="mx-4 my-2">
                <b-button
                    variant="secondary"
                    size="xs"
                    block
                    @click="onDisplayGeo"
                >
                    <b-icon-geo-alt-fill /> Afficher ces fiches sur la carte
                </b-button>
            </div>
            <div
                class="row-fiche"
                :class="{ selected: selected && selected.id === fiche.id }"
                v-for="fiche in currentPageContent"
                :key="fiche.id"
                @click="onFicheSelected(fiche)"
            >
                <b-badge variant="kalkin-2">
                    {{ fiche.template }}
                </b-badge>
                <span class="k-text-sm flex-grow-1">{{ fiche.name }}</span>
                <b-icon-arrow-right-circle-fill variant="light" />
            </div>
        </div>
        <div v-else class="text-center py-3">
            <i>Aucune fiche</i>
        </div>
        <div>
            <b-pagination
                class="m-auto"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
                first-number
                last-number
                @change="onPageChanged"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        fiches: { type: Array, required: true },
        perPage: { type: Number, default: 15 },
    },

    data: function() {
        return {
            selected: null,

            currentPage: 1,
            filter: "",
        };
    },

    computed: {
        filteredFiches() {
            return this.fiches.filter(f =>
                (f.name || "")
                    .toLowerCase()
                    .includes(this.filter.toLowerCase()),
            );
        },

        totalRows() {
            return this.filteredFiches.length;
        },

        currentPageContent() {
            return this.filteredFiches.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage,
            );
        },
    },

    methods: {
        onFicheSelected(fiche) {
            if (fiche !== this.selected) {
                this.selected = fiche;
                this.$emit("select-fiche", fiche);
            } else {
                this.selected = null;
                this.$emit("select-fiche", null);
            }
        },

        onDisplayGeo() {
            this.selected = null;
            this.$emit("select-fiche", null);

            const geometries = this.currentPageContent.reduce((acc, f) => {
                if (f.geo) {
                    acc.push(f.geo);
                }
                if (f.fiches) {
                    for (const poi of f.fiches) {
                        if (poi.geo) {
                            acc.push(poi.geo);
                        }
                    }
                }
                return acc;
            }, []);
            this.$emit("display-geometry", geometries);
        },

        onPageChanged() {
            this.$emit("display-geometry", null);
        },
    },
};
</script>

<style lang="scss" scoped>
.row-fiche {
    display: flex;
    align-items: center;
    gap: 5px;

    padding: 5px;

    &:nth-child(2n) {
        background: #dedede;
    }
    &:hover {
        background: #aaa;
        cursor: pointer;
    }
    & > svg {
        visibility: hidden;
    }
    &.selected {
        background: $primary-color;
        & > svg {
            visibility: visible;
        }
    }
}
</style>
