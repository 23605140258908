<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Édition de la source de données</h1>
            <div class="k-flex-col text-center">
                <small>
                    {{ datasource.DataSourceType.name }}
                </small>
                <b class="text-kalkin-1">
                    {{ datasource.name }}
                </b>
            </div>
        </div>
        <b-tabs class="w-100">
            <b-tab active>
                <template #title>
                    <b-icon-list-task />
                    Gestion des sélections de données
                </template>
                <div>
                    <div class="k-flex-row">
                        <h4>Liste des sélections de données</h4>
                        <b-button
                            size="sm"
                            variant="outline-success"
                            v-b-modal.ModalCreateDataselection
                        >
                            <b-icon-plus-circle /> Nouvelle sélection de données
                        </b-button>
                    </div>

                    <TableDataselection
                        :dataselections="dataselections"
                        :is-loading="isLoading"
                        @item-clicked="onDataselectionSelected"
                        @item-delete="onDataselectionDelete"
                    />
                </div>
            </b-tab>

            <b-tab>
                <template #title>
                    <b-icon-cloud-download />
                    Aperçu des sélections de données
                </template>
                <PanelPreviewDataselection
                    :dataselections="dataselections"
                    :project-id="projectId"
                />
            </b-tab>

            <b-tab lazy>
                <template #title>
                    <b-icon-gear />
                    Configuration
                </template>
                <FormDatasource
                    :key="datasource.id"
                    :project-id="projectId"
                    :datasource="datasource"
                />
            </b-tab>
            <b-tab lazy>
                <template #title>
                    <b-icon-trash />
                    Supprimer
                </template>
                <PanelDeleteDataSource
                    :key="datasource.id"
                    :datasource="datasource"
                    :project-id="projectId"
                />
            </b-tab>
        </b-tabs>

        <ModalCreateDataselection
            :projectId="projectId"
            :datasource="datasource"
            @saved="onDataselectionCreated"
        />
        <ModalUpdateDataselection
            :projectId="projectId"
            :datasource="datasource"
            :dataselection="selectedDataselection"
            @saved="onDataselectionUpdated"
        />
        <ModalConfirmDelete @confirmed="onDeleteConfirmed" />
    </div>
</template>
<script>
import FormDatasource from "@/components/model/datasource/FormDatasource";
import ModalCreateDataselection from "@/components/model/dataselection/ModalCreateDataselection";
import ModalUpdateDataselection from "@/components/model/dataselection/ModalUpdateDataselection";
import ModalConfirmDelete from "@/components/modal/ModalConfirmDelete";
import TableDataselection from "@/components/model/dataselection/TableDataselection";
import PanelPreviewDataselection from "@/components/model/dataselection/PanelPreviewDataselection";
import PanelDeleteDataSource from "@/components/model/datasource/PanelDeleteDataSource";

export default {
    components: {
        FormDatasource,
        ModalCreateDataselection,
        ModalUpdateDataselection,
        ModalConfirmDelete,
        TableDataselection,
        PanelPreviewDataselection,
        PanelDeleteDataSource,
    },

    data: function() {
        return {
            projectId: this.$route.params.projectId,
            datasourceId: this.$route.params.datasourceId,
            datasource: { name: "-", DataSourceType: { name: "-" } },
            dataselections: [],
            selectedDataselection: null,
            isLoading: true,
            contentTypeOptions: [
                { value: "auto", text: "Automatique" },
                { value: "service", text: "Service" },
                { value: "rando", text: "Rando" },
                { value: "circuit", text: "Circuit" },
            ],
        };
    },

    methods: {
        onDataselectionCreated() {
            this.loadDataselections();
        },

        onDataselectionSelected(item) {
            this.selectedDataselection = item;
            this.$bvModal.show("ModalUpdateDataselection");
        },

        onDataselectionUpdated() {
            this.$bvModal.hide("ModalUpdateDataselection");
            this.selectedDataselection = null;
            this.loadDataselections();
        },

        onDataselectionDelete(item) {
            this.selectedDataselection = item;
            this.$bvModal.show("ModalConfirmDelete");
        },

        async onDeleteConfirmed() {
            if (this.selectedDataselection) {
                await this.$store.dispatch("datasource/deleteDataSelection", {
                    projectId: this.projectId,
                    dataselectionId: this.selectedDataselection.id,
                });
                this.selectedDataselection = null;
                await this.loadDataselections();
            } else {
                await this.$store.dispatch("datasource/deleteDataSource", {
                    projectId: this.projectId,
                    datasourceId: this.datasourceId,
                });
                this.$router.go(-1);
            }
        },

        async loadDataselections() {
            this.isLoading = true;
            this.dataselections = await this.$store.dispatch(
                "datasource/getDataSelections",
                {
                    projectId: this.projectId,
                    datasourceId: this.datasourceId,
                },
            );
            this.isLoading = false;
        },
    },

    async beforeMount() {
        this.datasource = await this.$store.dispatch(
            "datasource/getDataSource",
            {
                projectId: this.projectId,
                datasourceId: this.datasourceId,
            },
        );
        if (this.datasource) {
            this.loadDataselections();
        } else {
            this.$router.push({
                name: "Project",
                params: { projectId: this.projectId },
            });
        }
    },
};
</script>

<style lang="scss" scoped>
::v-deep .tabs {
    display: flex;
    flex-direction: column;
    flex: 1;
}

::v-deep .nav-link {
    border: 1px solid white;
    border-color: #f5f5f5 #f5f5f5 transparent #f5f5f5;
}

::v-deep .tab-content {
    display: flex;
    flex-direction: column;
    & > div {
        flex: 1;
    }
    flex: 1;

    border: 1px solid #dee2e6;
    border-top: none;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding: 1rem;
}
</style>
